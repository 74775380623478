import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';

import { CLASS_NAMES, TAILWIND_CLASS_NAMES } from './constans';
import { handleFormFields } from './utils';

// Options
// {
// inputTelInstanse
// inputTelClassName
// errorCb
// successCb
// }

export class ValidationForm {
  constructor($form, options) {
    this.$form = $form;
    this.options = options;
    this.errorCb = options.errorCb || (() => {});
    this.successCb = options.successCb || (() => {});

    this.isValidForm = true;

    this.#init();
  }

  get inputTelInstanse() {
    return this.options.inputTelInstanse;
  }

  get inputTelClassName() {
    return this.options.inputTelClassName;
  }

  #init = () => {
    this.$form.addEventListener('submit', this.#formSubmitHandler);
  };

  #formSubmitHandler = (e) => {
    this.#reset();

    // e.preventDefault();
    // e.stopImmediatePropagation();

    this.$form
      .querySelectorAll('input')
      .forEach(this.#inputValidation.bind(this));

    if (this.isValidForm) {
      this.successCb(e);
    } else {
      this.errorCb(e);
    }
  };

  #inputValidation($input) {
    if (!$input.required) return;

    let isValidField;

    const value = $input.value.trim();

    switch ($input.type) {
      case 'text':
        isValidField = !isEmpty(value);
        break;
      case 'email':
        isValidField = isEmail(value);
        break;
      case 'tel':
        isValidField = this.inputTelInstanse.isValidNumberPrecise();
    }

    if (isValidField) {
      this.#hideError($input);
    } else {
      this.isValidForm = false;

      this.#showError($input);
    }
  }

  #reset() {
    this.isValidForm = true;
  }

  #hideError($input) {
    if ($input.type === 'tel') {
      this.$form
        .querySelector(`.${this.inputTelClassName}`)
        .parentElement.classList.remove(CLASS_NAMES.invalid);
    } else {
      $input.parentElement.classList.remove(CLASS_NAMES.invalid);
    }
  }

  #showError($input) {
    if ($input.type === 'tel') {
      this.$form
        .querySelector(`.${this.inputTelClassName}`)
        .parentElement.classList.add(CLASS_NAMES.invalid);
    } else {
      $input.parentElement.classList.add(CLASS_NAMES.invalid);
    }
  }
}

export class ControlForm {
  constructor($form) {
    this.$form = $form;
    this.$submitButton = $form.querySelector('button[type="submit"]');
    this.$buttonContent =
      this.$submitButton.querySelector('.js-button-content');
    this.$buttonLoader = this.$submitButton.querySelector('.js-button-loader');
    this.$message = this.$form.querySelector('.js-form-message');
    this.submitButtonLabel = this.$submitButton.textContent;
  }

  resetForm = () => {
    this.$form.reset();

    return this;
  };

  disableSubmitButton = () => {
    this.$submitButton.setAttribute('disabled', true);

    return this;
  };

  enableSubmitButton = () => {
    this.$submitButton.removeAttribute('disabled', true);

    return this;
  };

  setLabelSubmitButton = (label) => {
    this.$buttonContent.textContent = label;

    return this;
  };

  resetLabelSubmitButton = () => {
    this.setLabelSubmitButton(this.submitButtonLabel);

    return this;
  };

  showLoader = () => {
    this.$buttonLoader.classList.add(CLASS_NAMES.show);

    return this;
  };

  hideLoader = () => {
    this.$buttonLoader.classList.remove(CLASS_NAMES.show);

    return this;
  };

  showMessage = () => {
    this.$message.classList.remove(TAILWIND_CLASS_NAMES.hide);

    return this;
  };

  hideMessage = () => {
    this.$message.classList.add(TAILWIND_CLASS_NAMES.hide);

    return this;
  };

  enableReadOnly = () => {
    handleFormFields(this.$form, ($input) => ($input.readOnly = true));

    return this;
  };

  disableReadOnly = () => {
    handleFormFields(this.$form, ($input) => ($input.readOnly = false));

    return this;
  };
}
