export const CLASS_NAMES = {
  invalid: 'invalid',
  show: 'show',
  active: 'active'
};

export const TAILWIND_CLASS_NAMES = {
  hide: 'hide',
  unvisible: 'unvisible'
};
