import intlTelInput from 'intl-tel-input';

import 'intl-tel-input/build/css/intlTelInput.min.css';

import {
  getElementByDataType,
  handleFormFields,
  safeCall
} from '../../common/utils';
import { ControlForm, ValidationForm } from '../../common/form';

safeCall(() => {
  const $contactUsForm = document.getElementById('contact-us-form');

  if (!$contactUsForm) return;

  const $inputTel = getElementByDataType('phone-field', $contactUsForm);

  const iti = intlTelInput($inputTel, {
    utilsScript:
      'https://cdn.jsdelivr.net/npm/intl-tel-input@23.6.1/build/js/utils.js',
    containerClass: 'm-tel-input input-field__input js-input-tel-wrapper',
    initialCountry: 'us',
    showFlags: false,
    separateDialCode: true
  });

  const controlFormI = new ControlForm($contactUsForm);

  new ValidationForm($contactUsForm, {
    inputTelInstanse: iti,
    inputTelClassName: 'js-input-tel-wrapper',
    successCb: () => {
      controlFormI
        .disableSubmitButton()
        .setLabelSubmitButton('Sending')
        .showLoader()
        .enableReadOnly();

      const formData = new FormData();
      formData.append('submit', 'Submit');

      handleFormFields($contactUsForm, ($input) => {
        if ($input.name === 'phone') {
          formData.append($input.name, iti.getNumber());
        } else {
          formData.append($input.name, $input.value);
        }
      });
    },
    errorCb: (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  });
});
