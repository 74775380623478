import { safeCall } from '../../common/utils';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

safeCall(() => {
  const showFromLeft = document.querySelectorAll('.show-from-left');
  const showFromRight = document.querySelectorAll('.show-from-right');
  const maskedHeadings = document.querySelectorAll('.masked-text');

  if (showFromLeft.length) {
    showFromLeft.forEach((item) => {
      gsap.set(item, {
        '--insetValue': '100%',
        clipPath: 'inset(0 var(--insetValue) 0 0)'
      });
      gsap.to(item, {
        duration: 1.2,
        ease: 'power1.out',
        '--insetValue': '0%',
        delay: 0.25,
        scrollTrigger: {
          trigger: item
        },
      });
    });
  }

  if (showFromRight.length) {
    showFromRight.forEach((item) => {
      gsap.set(item, {
        '--insetValue': '100%',
        clipPath: 'inset(0 0 0 var(--insetValue))'
      });
      gsap.to(item, {
        duration: 1.2,
        ease: 'power1.out',
        '--insetValue': '0%',
        delay: 0.25,
        scrollTrigger: {
          trigger: item
        },
      });
    });
  }

  if (maskedHeadings.length) {
    maskedHeadings.forEach((item) => {
      gsap.set(item, {
        '--text-fill': '0%'
      });
      gsap.to(item, {
        '--text-fill': '100%',
        duration: 1.2,
        ease: 'power1.out',
        delay: 0.25,
        scrollTrigger: {
          trigger: item
        }
      });
    });
  }


  // const $industryList = document.querySelector('.js-industry');
  // const industryItems = $industryList.querySelectorAll('li');

  // const tl = gsap.timeline({ repeat: -1 });

  // tl.fromTo(
  //   industryItems,
  //   {
  //     clipPath: 'inset(0 0 0 0%)'
  //   },
  //   {
  //     duration: 2,
  //     ease: 'power1.out',
  //     clipPath: 'inset(0 0 0 100%)',
  //     stagger: 0.8
  //   }
  // );
  // tl.fromTo(
  //   industryItems[0],
  //   {
  //     clipPath: 'inset(0 0 0 0%)'
  //   },
  //   {
  //     duration: 0.6,
  //     ease: 'power1.out',
  //     clipPath: 'inset(0 0 0 100%)'
  //   }
  // ).fromTo(
  //   industryItems[1],
  //   {
  //     clipPath: 'inset(0 100% 0 0)'
  //   },
  //   {
  //     duration: 0.6,
  //     ease: 'power1.in',
  //     clipPath: 'inset(0 0% 0 0)',
  //     delay: 0.2
  //   },
  //   '<'
  // );

  // industryItems.forEach($item => {
  //   gsap.set($item, {
  //     clipPath: 'inset(0 0 0 0)',
  //   })

  //   const tl = gsap.timeline( {repeat:-1, repeatDelay: 5} );

  //   tl.fromTo($item, {
  //     ease: 'power1.out',
  //     clipPath: 'inset(0 0 0 0%)',
  //     scrollTrigger: {
  //       trigger: $industryList
  //     },
  //   },             {
  //     duration: 0.6,
  //     ease: 'power1.out',
  //     clipPath: 'inset(0 0 0 100%)'
  //   })
  // })
});
