import { CLASS_NAMES, TAILWIND_CLASS_NAMES } from './constans';

export class Tab {
  constructor(options) {
    this.$tabs = options.$tabs;
    this.$tabHandlers = options.$tabHandlers;
    this.onChangeCb = options.onChangeCb;

    this.currentTabId = null;
    this.#init();
  }

  #TAB_HANDLER_TYPE = 'tab-handler';

  #init() {
    this.$tabHandlers.addEventListener('click', this.#onSwitchTab.bind(this));

    this.currentTabId = this.$tabHandlers.querySelector(
      '[data-tab-active="true"]'
    ).dataset?.tabId;
  }

  #onSwitchTab = (e) => {
    const $target = e.target;

    if ($target.dataset?.type !== this.#TAB_HANDLER_TYPE) return;

    const { tabId } = $target.dataset;

    if (this.currentTabId === tabId) return;

    const $prevTab = this.$tabs.querySelector(
      `[data-tab-id="${this.currentTabId}"]`
    );
    const $nextTab = this.$tabs.querySelector(`[data-tab-id="${tabId}"]`);

    if (this.onChangeCb) {
      this.onChangeCb($prevTab, $nextTab);
      this.#resetStateHandlers().#setActiveTabHanler($target);
    } else {
      this.#hideTab($prevTab)
        .#resetStateHandlers()
        .#showTab($nextTab)
        .#setActiveTabHanler($target);
    }

    this.currentTabId = tabId;
  };

  #showTab = ($tab) => {
    $tab.classList.remove(TAILWIND_CLASS_NAMES.hide);

    return this;
  };

  #hideTab = ($tab) => {
    $tab.classList.add(TAILWIND_CLASS_NAMES.hide);

    return this;
  };

  #setActiveTabHanler = ($handler) => {
    $handler.classList.add(CLASS_NAMES.active);

    return this;
  };

  #resetStateHandlers = () => {
    this.$tabHandlers
      .querySelectorAll('[data-type="tab-handler"]')
      .forEach(($handler) => $handler.classList.remove(CLASS_NAMES.active));

    return this;
  };
}
