import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';

document.addEventListener('DOMContentLoaded', () => {
    const radioButtons = document.querySelectorAll('input[name="radio_option"]');
    const repeaterGroups = document.querySelectorAll('.repeater-group');

    function handleRadioChange() {
        const selectedValue = document.querySelector('input[name="radio_option"]:checked').value;

        repeaterGroups.forEach(group => {
            if (group.classList.contains('repeater-group-' + selectedValue)) {
                group.style.display = 'block'; // Show the selected block
            } else {
                group.style.display = 'none'; // Hide non-selected blocks
            }
        });
    }

    // Attach event listeners to radio buttons
    radioButtons.forEach(button => {
        button.addEventListener('change', handleRadioChange);
    });

    // Ensure the first radio button is selected by default
    const firstButton = radioButtons[0];
    if (firstButton) {
        firstButton.checked = true;
        handleRadioChange(); // Trigger the initial display
    }
    // Initialize Swiper if screen width is <= 728px
    if (window.innerWidth <= 728) {
        const swiperContainers = document.querySelectorAll('.swiper-container');
        
        swiperContainers.forEach(container => {
            new Swiper(container, {
                modules: [Navigation],
                navigation: {
                    nextEl: '[data-type="slider-nav-next"]',
                    prevEl: '[data-type="slider-nav-prev"]',
                },
                slidesPerView: 1,
                spaceBetween: 10,
                loop: true,
            });
        });
    }
});