import { TAILWIND_CLASS_NAMES } from './constans';

export const getElementByDataType = (dataType, $el) => {
  if ($el) {
    return $el.querySelector(`[data-type="${dataType}"]`);
  }

  return document.querySelector(`[data-type="${dataType}"]`);
};

export const safeReportError = (err) => {
  if ('Sentry' in window) {
    return window.Sentry.captureException(err);
  }

  if (typeof window.reportError === 'function') {
    return window.reportError(err);
  }

  console.error(err);
};

export const safeCall = (fn) => {
  try {
    fn();
  } catch (err) {
    safeReportError(err);
  }
};

export const handleFormFields = ($form, cb) => {
  $form.querySelectorAll('input, textarea').forEach(($input) => {
    // Skip an input tel search field
    if ($input.className === 'iti__search-input') return;

    cb($input);
  });
};

export const compose = function () {
  const funcs = Array.prototype.slice.call(arguments);

  return funcs.reduce(function (f, g) {
    return function () {
      return f(g.apply(this, arguments));
    };
  });
};

export const customFormatFraction = (number) =>
  number > 9 ? number : `0${number}`;

export const visibleSwiperNav = (slidesCount, elements = []) => {
  if (slidesCount > 1) return;

  elements.forEach(($nav) => $nav.classList.add(TAILWIND_CLASS_NAMES.hide));
};
