import {safeCall} from "../../common/utils";

safeCall(async () => {
  const lottieAnimations = document.querySelectorAll('canvas.dotLottie');
  if (lottieAnimations.length) {
    import('@lottiefiles/dotlottie-web').then(({ DotLottie }) => {
      const lottieObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const item = entry.target;
            if (item.lottie) {
              item.lottie.play();
            }
          }
        });
      });

      lottieAnimations.forEach((item) => {
        const lottiePath = item.dataset.path;
        const lottie = new DotLottie({
          autoplay: false,
          loop: false,
          canvas: item,
          src: lottiePath,
          speed: 0.6,
          renderConfig: {
            devicePixelRatio: window.devicePixelRatio,
          }
        });

        const animateOnScroll = item.dataset.playOnScroll === "1";

        lottie.addEventListener('load', () => {
          item.lottie = lottie;

          if (animateOnScroll) {
            lottieObserver.observe(item);
          }
        });

        lottie.addEventListener('error', (error) => {
          console.error(`Failed to load Lottie animation from ${lottiePath}:`, error);
        });
      });
    })
  }
})

