import gsap from 'gsap';
import { Tab } from '../../common/tab';
import { getElementByDataType, safeCall } from '../../common/utils';

safeCall(() => {
  const $section = getElementByDataType('our-x-factors-section');

  if (!$section) return;
  const $desktopSlider = $section.querySelector('.desktop-slider');

  const $tabs = getElementByDataType('our-x-factors-tab', $section);
  const $tabHandlers = getElementByDataType(
    'our-x-factors-tab-handlers',
    $section
  );

  if(!$desktopSlider) return;
  const $slides = $desktopSlider.querySelectorAll('.desktop-slide');
  const $slidesArray = Array.from($slides);

  const forwardGradient = 'linear-gradient(to right, #fff 0%, #fff var(--fill-progress), transparent var(--fill-progress), transparent 100% )'
  const backwardGradient = 'linear-gradient(to left, #fff 0%, #fff var(--fill-progress), transparent var(--fill-progress), transparent 100% )'

  gsap.set($slides, {
    // '--fill-progress': '0%',
    // 'mask' : forwardGradient
    opacity: 0
  });

  // const openSlide = (index) => {
  //   const currentSlide = $slidesArray[index];
  //   const prevSlide = $slidesArray.find(slide => slide.classList.contains('active'));
  //   prevSlide?.classList?.remove('active');
  //   currentSlide.classList.add('active');
  //   gsap.set(prevSlide, { 'mask' : backwardGradient })
  //   gsap.set(currentSlide, { 'mask' : forwardGradient })
  //   gsap.timeline({
  //     onInterrupt: () => {
  //       gsap.set(prevSlide, { 'mask' : backwardGradient })
  //       gsap.set(currentSlide, { 'mask' : forwardGradient })
  //       gsap.set(prevSlide, {
  //         '--fill-progress': '0%',
  //         duration: 0.5
  //       })
  //       gsap.set(currentSlide, {
  //         '--fill-progress': '100%',
  //         duration: 0.5
  //       })
  //     }
  //   })
  //     .to(prevSlide, {
  //       '--fill-progress': '0%',
  //       duration: 0.5
  //     })
  //     .to(currentSlide, {
  //       '--fill-progress': '100%',
  //       duration: 0.5,
  //       onComplete: () => {
  //         gsap.set(prevSlide, {
  //           'mask' : forwardGradient,
  //           '--fill-progress': '0%'
  //         })
  //       }
  //     }, '-=0.3')
  // }
  // openSlide(0)


  const openSlide = (index) => {
    const currentSlide = $slidesArray[index];
    const prevSlide = $slidesArray.find(slide => slide.classList.contains('active'));
    prevSlide?.classList?.remove('active');
    currentSlide.classList.add('active');
    const lottieCanvas = currentSlide.querySelector('.x-factors-slide-lottie')
    // console.log(lottieCanvas?.lottie);
    lottieCanvas?.lottie?.setFrame(0);
    // gsap.set(prevSlide, { 'mask' : backwardGradient })
    // gsap.set(currentSlide, { 'mask' : forwardGradient })
    gsap.timeline({
      onInterrupt: () => {
        gsap.set(prevSlide, { opacity: 0 })
        gsap.set(currentSlide, { opacity: 1 })
        // gsap.set(prevSlide, { 'mask' : backwardGradient })
        // gsap.set(currentSlide, { 'mask' : forwardGradient })
        // gsap.set(prevSlide, {
        //   '--fill-progress': '0%',
        //   duration: 0.5
        // })
        // gsap.set(currentSlide, {
        //   '--fill-progress': '100%',
        //   duration: 0.5
        // })
      }
    })
      .to(prevSlide, {
        opacity: 0,
        duration: 0.5
      })
      .to(currentSlide, {
        opacity: 1,
        duration: 0.5,
        onComplete: () => {
          gsap.set(prevSlide, {
            opacity: 0,
            // 'mask' : forwardGradient,
            // '--fill-progress': '0%'
          })
          
          lottieCanvas?.lottie.play();
        }
      }, '-=0.3')
  }
  openSlide(0)




  const onChangeTab = ($prevTab, $nextTab) => {
    const $prevTabTitle = getElementByDataType('factor-tab-title', $prevTab);
    const $prevTabText = getElementByDataType('factor-tab-text', $prevTab);
    const $nextTabTitle = getElementByDataType('factor-tab-title', $nextTab);
    const $nextTabText = getElementByDataType('factor-tab-text', $nextTab);

    const ind = Array.from($tabs.children).findIndex($tab => $tab === $nextTab);
    openSlide(ind);

    const tl = gsap.timeline();

    tl.fromTo(
      [$prevTabTitle, $prevTabText],
      {
        y: 0,
        opacity: 1
      },
      {
        y: '10%',
        opacity: 0,
        duration: 0.2,
        ease: 'power4.out'
      }
    )
      .to($prevTab, {
        opacity: 0,
        display: 'none',
        duration: 0
      })
      .to($nextTab, {
        opacity: 1,
        display: 'flex',
        duration: 0
      })
      .fromTo(
        [$nextTabTitle, $nextTabText],
        {
          y: '-10%',
          opacity: 0
        },
        {
          y: 1,
          opacity: 1,
          duration: 0.2,
          ease: 'power4.out'
        }
      );
  };

  new Tab({
    $tabs,
    $tabHandlers,
    onChangeCb: onChangeTab
  });
});
