import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade } from 'swiper/modules';
import gsap from 'gsap';

import {
  customFormatFraction,
  getElementByDataType,
  safeCall
} from '../../common/utils';

import 'swiper/css';
import 'swiper/css/effect-fade';

safeCall(() => {
  const $slider = getElementByDataType('testimonials-slider');

  if (!$slider) return;

  const $pagination = getElementByDataType('slider-pagination', $slider);
  const $navPrev = getElementByDataType('slider-nav-prev', $slider);
  const $navNext = getElementByDataType('slider-nav-next', $slider);

  new Swiper($slider, {
    modules: [Navigation, Pagination, EffectFade],
    loop: false,
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      enabled: true,
      nextEl: $navNext,
      prevEl: $navPrev
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    pagination: {
      enabled: true,
      el: $pagination,
      type: 'fraction',
      formatFractionCurrent: customFormatFraction,
      formatFractionTotal: customFormatFraction
    },
    on: {
      slideChange: (swiper) => {
        const { slides, previousIndex, activeIndex } = swiper;
        const tl = gsap.timeline();

        const $prevSlide = slides[previousIndex];
        const $nextSlide = slides[activeIndex];

        const $nextText = getElementByDataType('slide-text', $nextSlide);
        const $nextLogo = getElementByDataType('slide-logo', $nextSlide);

        tl.set($nextSlide, { height: '100%' })
          .fromTo(
            $prevSlide,
            {
              opacity: 1,
              clipPath: 'inset(0 0 0 0%)',
              position: 'relative',
              zIndex: 10
            },
            {
              duration: 0.6,
              ease: 'power1.out',
              clipPath: 'inset(0 0 0 100%)'
            }
          )
          .fromTo(
            [$nextText, $nextLogo],
            {
              y: '-20%',
              opacity: 0
            },
            {
              y: 1,
              opacity: 1,
              duration: 0.4,
              ease: 'power1.out'
            }
          )
          .set($prevSlide, {
            opacity: 0,
            zIndex: 0,
            clipPath: 'inset(0 0 0 0%)',
            height: 0
          });
      }
    }
  });
});
