import {safeCall} from "../../common/utils";
import "color-calendar/dist/css/theme-basic.css";

const wpGlobals = window.wp_globals;
const calendlyToken = wpGlobals?.calendly_token;


const calendlyBaseUrl = 'https://api.calendly.com';
const requestHeaders = {
  'Authorization': `Bearer ${calendlyToken}`,
  'Content-Type': 'application/json'
};

const state = {
  currentTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  startTimeISO: null,
  endTimeISO: null,
  availableSlots: [],
}

safeCall(async () => {
  const calendars = document.querySelectorAll('.app-calendar');
  if(!calendars.length) return;
  const Calendar = (await import("color-calendar")).default;

  const calendlyCurrentDate = document.querySelector('.calendly-date');
  const calendlyTimeTemplate = document.querySelector('#calendly-time-template');
  const calendlyTimes = document.querySelector('.calendly-times');
  const button = document.querySelector('.calendly-book-event');
  const preloaderTemplate = document.querySelector('#calendly-preloader');
  const noSlotsTemplate = document.querySelector('#calendly-no-slots');
  let selectedDate = null;

  const user = await getUser();
  const userUri = user?.resource?.uri;
  const eventType = await getEventType(userUri);

  const timezoneSelect = document.getElementById('timezone');
  const timezoneChoices = timezoneSelect.choices;
  timezoneChoices.setChoiceByValue(state.currentTimezone);

  timezoneSelect.addEventListener('change', async (e) => {
    const value = e.detail.value;
    state.currentTimezone = value;
    if(!state.startTimeISO) return;
    const date = new Date(state.startTimeISO.split('T')[0]);
    const availableSlots = await getAvailableTimeSlots(date);
    calendlyTimes.innerHTML = '';
    availableSlots.forEach(slot => {
      const timeSlot = calendlyTimeTemplate.content.cloneNode(true);
      const localTime = formatToLocalTime(slot.start_time, value);
      const slotElement = timeSlot.querySelector('.calendly-time');
      slotElement.innerText = localTime;
      slotElement.dataset.link = slot.scheduling_url;
      slotElement.dataset.time = slot.start_time;
      calendlyTimes.appendChild(timeSlot);
    });
  });

  const onTimeSlotClick = (e) => {
    const currentTarget = e.target;
    if(currentTarget.classList.contains('is-selected')) return;
    if(currentTarget.classList.contains('calendly-time')) {
      const allTimeSlots = document.querySelectorAll('.calendly-time');
      allTimeSlots.forEach(slot => {
        slot.classList.remove('is-selected');
        slot.dataset.selected = false;
      });
      currentTarget.classList.add('is-selected');
      currentTarget.dataset.selected = true;
      selectedDate = currentTarget.dataset.time;
      const link = currentTarget.dataset.link;

      button.href = link;
    }
  }
  calendlyTimes.addEventListener('click', onTimeSlotClick);

  async function getAvailableTimeSlots(date) {
    const selectedDate = new Date(date);
    const currentDateString = selectedDate.toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
    calendlyCurrentDate.innerText = currentDateString;

    const currentISOHours = selectedDate.toDateString() === new Date().toDateString() ? new Date().getUTCHours() + 1 : 0
    const startTimeISO = getDateTimeInISO(selectedDate, currentISOHours, 0, 0, 0);
    const endTimeISO = getDateTimeInISO(selectedDate, 23, 59, 59, 999);
    state.startTimeISO = startTimeISO;
    state.endTimeISO = endTimeISO;

    const timeSlots = await getCalendlyTimeSlots(eventType.uri, startTimeISO, endTimeISO);
    return timeSlots || [];
  }

  calendars.forEach(item => {
    new Calendar({
      id: '#' + item.id,
      calendarSize: "small",
      disableMonthYearPickers: true,
      customMonthValues: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      dateChanged: async (date) => {
        calendlyTimes.innerHTML = '';
        button.href = '';
        const preloader = preloaderTemplate.content.cloneNode(true);
        calendlyTimes.appendChild(preloader);

        const availableSlots = await getAvailableTimeSlots(date);
        calendlyTimes.innerHTML = '';
        if(!availableSlots?.length) {
          const noSlots = noSlotsTemplate.content.cloneNode(true);
          calendlyTimes.appendChild(noSlots);
          return;
        }
        availableSlots.forEach(slot => {
          const timeSlot = calendlyTimeTemplate.content.cloneNode(true);
          const localTime = formatToLocalTime(slot.start_time, state.currentTimezone);
          const slotElement = timeSlot.querySelector('.calendly-time');
          slotElement.innerText = localTime;
          slotElement.dataset.link = slot.scheduling_url;
          slotElement.dataset.time = slot.start_time;
          calendlyTimes.appendChild(timeSlot);
        });
      },
    });
  });
});

async function getUser(){
  const url = `${calendlyBaseUrl}/users/me`;
  try {
    const userResponse = await fetch(url, { headers: requestHeaders });
    const userData = await userResponse.json();
    return userData || null;
  } catch (error) {
    console.error('Error fetching Calendly data:', error);
    return null;
  }
}

async function getEventType(userUri){
  const url = `${calendlyBaseUrl}/event_types?user=${userUri}`;
  try{
    const response = await fetch(url, { headers: requestHeaders });
    const data = await response.json();
    return data?.collection[0] || null;
  } catch (error) {
    console.error('Error fetching Calendly data:', error);
    return null;
  }
}

async function getCalendlyTimeSlots(eventTypeUri, startTimeISO, endTimeISO){
  const url = `${calendlyBaseUrl}/event_type_available_times?event_type=${eventTypeUri}&start_time=${startTimeISO}&end_time=${endTimeISO}`;
  try{
    const response = await fetch(url, { headers: requestHeaders });
    const data = await response.json();
    return data?.collection || [];
  } catch (error) {
    console.error('Error fetching Calendly data:', error);
    return [];
  }
}

function getDateTimeInISO(date, hours, minutes, seconds, milliseconds) {
  const d = new Date(date);
  const isoString = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + 'T' + hours + ':' + minutes + ':' + seconds + '.' + milliseconds + 'Z';
  return isoString;
}

function formatToLocalTime(isoString, timeZone) {
  const date = new Date(isoString);
  return new Intl.DateTimeFormat('en-CA', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timeZone,
    // la
    hour12: true
  }).format(date);
}
