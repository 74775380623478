document.addEventListener('DOMContentLoaded', function() {
    function initializeVideoPlayer(videoId, playPauseId, muteId, progressBarId) {
        const video = document.getElementById(videoId);
        const progressBar = document.getElementById(progressBarId);
        if(!video) return

        function updateProgressBar() {
            const value = (video.currentTime / video.duration) * 100;
            progressBar.style.width = `${value}%`;

            if (!video.paused && !video.ended) {
                requestAnimationFrame(updateProgressBar);
            }
        }


        document.getElementById(playPauseId).addEventListener('click', function() {
            if (video.paused || video.ended) {
                video.play();
                requestAnimationFrame(updateProgressBar);
            } else {
                video.pause();
            }
        });


        document.getElementById(muteId).addEventListener('click', function() {
            video.muted = !video.muted;
        });


        video.addEventListener('play', function() {
            requestAnimationFrame(updateProgressBar);
        });

        progressBar.parentNode.addEventListener('click', function(e) {
            const rect = this.getBoundingClientRect();
            const offsetX = e.clientX - rect.left;
            const seekTime = (offsetX / this.offsetWidth) * video.duration;
            video.currentTime = seekTime;
            updateProgressBar();
        });
    }


    initializeVideoPlayer('desktopVideo', 'desktopPlayPause', 'desktopMute', 'desktopProgressBar');
    initializeVideoPlayer('mobileVideo', 'mobilePlayPause', 'mobileMute', 'mobileProgressBar');
});



document.addEventListener('DOMContentLoaded', function() {
    // Get the play button, overlay, close button, and popup video element
    const playButton = document.getElementById('playButton');
    if(!playButton) return;
    const playButtonMobile = document.getElementById('playButtonMobile'); // mobile play button
    const overlay = document.getElementById('overlay');
    const closePopup = document.getElementById('closePopup');
    const popupVideo = document.getElementById('desktopVideo');

    // Function to disable scroll
    function disableScroll() {
        document.body.style.overflow = 'hidden'; // Disable scrolling
    }

    // Function to enable scroll
    function enableScroll() {
        document.body.style.overflow = ''; // Enable scrolling
    }

    // Function to center the overlay in the viewport
    function centerOverlay() {
        const rect = overlay.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Calculate the scroll position to center the overlay
        const scrollTo = window.scrollY + (rect.top + rect.height / 2) - (windowHeight / 2);
        window.scrollTo({
            top: scrollTo,
            behavior: 'smooth' 
        });
    }

    // Function to darken background elements with bg-yellow
    function darkenBackground() {
        const elements = document.querySelectorAll('.bg-yellow');
        elements.forEach(element => {
            element.style.backgroundColor = '#504b10'; // Change to dark color
        });
        document.body.classList.add('darkened'); // Add darkened class to body
    }

    // Function to lighten background elements with bg-yellow
    function lightenBackground() {
        const elements = document.querySelectorAll('.bg-yellow');
        elements.forEach(element => {
            element.style.backgroundColor = ''; // Reset to original color
        });
        document.body.classList.remove('darkened'); // Remove darkened class from body
    }

    // Function to show the pop-up and darken background
    const showOverlay = function() {
        overlay.classList.remove('hidden'); // Show overlay
        overlay.style.opacity = 1; // Fade in
        popupVideo.play(); // Play video
        disableScroll(); // Disable scrolling
        darkenBackground(); // Darken background elements
        centerOverlay(); // Center overlay in the viewport
    };

    // Add click events to both play buttons
    playButton.addEventListener('click', showOverlay);
    playButtonMobile.addEventListener('click', showOverlay); // Add event for mobile play button

    // Function to close the pop-up, stop video, and remove dark background
    closePopup.addEventListener('click', function() {
        overlay.classList.add('hidden'); // Hide overlay
        popupVideo.pause(); // Pause video
        popupVideo.currentTime = 0; // Reset video to start
        enableScroll(); // Enable scrolling
        lightenBackground(); // Lighten background elements
    });

    // close the pop-up if the overlay is clicked
    overlay.addEventListener('click', function(event) {
        if (event.target === overlay) { // Check if clicked on the overlay
            overlay.classList.add('hidden'); // Hide overlay
            popupVideo.pause(); // Pause video
            popupVideo.currentTime = 0; // Reset video to start
            enableScroll(); // Enable scrolling
            lightenBackground(); // Lighten background elements
        }
    });
});
