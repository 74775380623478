import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade } from 'swiper/modules';
import gsap from 'gsap';

import {
  customFormatFraction,
  getElementByDataType,
  safeCall,
  visibleSwiperNav
} from '../../common/utils';

import 'swiper/css';
import 'swiper/css/effect-fade';

const forwardGradient = 'linear-gradient(to right, #fff 0%, #fff var(--fill-progress), transparent var(--fill-progress), transparent 100% )'
const backwardGradient = 'linear-gradient(to left, #fff 0%, #fff var(--fill-progress), transparent var(--fill-progress), transparent 100% )'

safeCall(() => {
  const $slider = getElementByDataType('how-it-works-slider');
  if(!$slider) return;
  const $pagination = getElementByDataType('slider-pagination', $slider);
  const $navPrev = getElementByDataType('slider-nav-prev', $slider);
  const $navNext = getElementByDataType('slider-nav-next', $slider);

  const images = Array.from($slider.querySelectorAll('[data-type="slide-image"]'));
  const titles = Array.from($slider.querySelectorAll('[data-type="slide-title"]'));
  const texts = Array.from($slider.querySelectorAll('[data-type="slide-text"]'));

  const swiper = new Swiper($slider, {
    modules: [Navigation, Pagination, EffectFade],
    loop: false,
    slidesPerView: 1,
    speed: 0,
    navigation: {
      enabled: true,
      nextEl: $navNext,
      prevEl: $navPrev
    },
    effect: 'fade',
    pagination: {
      enabled: true,
      el: $pagination,
      clickable: true,
      renderBullet: (index, className) => {
        const pagin = pagins[index];
        return `<span class="${className}" data-title="${titles[index].textContent}">${pagin}</span>`;
      }
    },
    on: {
      init: (swiper) => {
        visibleSwiperNav(swiper.slides);
        const $images = Array.from($slider.querySelectorAll('[data-type="slide-image"]'));
        gsap.set($images, { '--fill-progress': '0%', mask: forwardGradient });
        gsap.to($images[0], { '--fill-progress': '100%', duration: 0.6, ease: 'power2.out' });
      },
      slideChange: (swiper) => {
        const { slides, previousIndex, activeIndex } = swiper;
    
        const $prevSlide = slides[previousIndex];
        const $nextSlide = slides[activeIndex];
    
        const $prevTitle = titles[previousIndex];
        const $prevText = texts[previousIndex];
        const $nextTitle = titles[activeIndex];
        const $nextText = texts[activeIndex];
    
        const $prevImage = images[previousIndex];
        const $nextImage = images[activeIndex];
    

        const tl = gsap.timeline({
          onStart: () => {

            gsap.set(slides, { opacity: 0 });
            gsap.set([$prevTitle, $prevText, $nextTitle, $nextText], { opacity: 0, y: 20 });
            gsap.set([$prevImage, $nextImage], { '--fill-progress': '0%', opacity: 0 });
          },
          defaults: {
            ease: 'power1.out',
            duration: 0.5,
          },
        });

        tl.set($nextSlide, { opacity: 1 })
          .fromTo($prevTitle, { y: 0, opacity: 1 }, { y: -20, opacity: 0 })
          .fromTo($nextTitle, { y: 20, opacity: 0 }, { y: 0, opacity: 1 }, '-=0.5')
          .fromTo($prevText, { y: 0, opacity: 1 }, { y: -20, opacity: 0 }, '-=0.5')
          .fromTo($nextText, { y: 20, opacity: 0 }, { y: 0, opacity: 1 }, '-=0.5')
          .fromTo($prevImage, { '--fill-progress': '100%', opacity: 1 }, { '--fill-progress': '0%', opacity: 0 }, '-=0.5')
          .fromTo($nextImage, { '--fill-progress': '0%', opacity: 0 }, { '--fill-progress': '100%', opacity: 1 }, '-=0.5');
      },
    }
  });

  // Add hover functionality
  const bullets = $pagination.querySelectorAll('.swiper-pagination-bullet');
  const isMobile = () => window.innerWidth <= 768;
  if (!isMobile()) {
  bullets.forEach((bullet, index) => {
    bullet.addEventListener('mouseenter', () => {
      // Get the title for the current bullet
      const title = bullet.getAttribute('data-title');

      // Create a new element to display the title above the pagination number and give him styles
      const titleElement = document.createElement('div');
      titleElement.textContent = title;
      titleElement.style.position = 'absolute';
      titleElement.style.top = '-30px'; 
      titleElement.style.color = '#893FFC'; 
      titleElement.style.width = '120%';
      titleElement.classList.add('font-h3'); 
      titleElement.style.textTransform = 'uppercase'; 
      bullet.appendChild(titleElement);


      bullets.forEach((otherBullet, otherIndex) => {
        if (otherIndex === swiper.activeIndex || otherIndex === index) {
          otherBullet.style.color = '#893FFC'; 
        } else {
          otherBullet.style.color = '#D1D3DB'; 
        }
      });
    });

    bullet.addEventListener('mouseleave', () => {

      const titleElement = bullet.querySelector('div');
      if (titleElement) {
        bullet.removeChild(titleElement);
      }

      bullets.forEach((otherBullet, otherIndex) => {
        if (otherIndex === swiper.activeIndex) {
          otherBullet.style.color = '#893FFC'; // Keep active bullet purple
        } else {
          otherBullet.style.color = 'black'; // Set others to black
        }
      });
    });
  });
  }
});